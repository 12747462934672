export function withTimeout(fn, {
  errorInstance = new Error('timed out'),
  timeout,
  signal
}) {
  return new Promise((resolve, reject) => {
    ;
    (async () => {
      let timeoutId;
      try {
        const controller = new AbortController();
        if (timeout > 0) {
          timeoutId = setTimeout(() => {
            if (signal) {
              controller.abort();
            } else {
              reject(errorInstance);
            }
          }, timeout); // need to cast because bun globals.d.ts overrides @types/node
        }
        resolve(await fn({
          signal: controller?.signal || null
        }));
      } catch (err) {
        if (err?.name === 'AbortError') reject(errorInstance);
        reject(err);
      } finally {
        clearTimeout(timeoutId);
      }
    })();
  });
}
