import { hexToBytes } from '../encoding/toBytes.js';
import { bytesToHex } from '../encoding/toHex.js';
/**
 * Compute the proofs for a list of blobs and their commitments.
 *
 * @example
 * ```ts
 * import {
 *   blobsToCommitments,
 *   toBlobs
 * } from 'viem'
 * import { kzg } from './kzg'
 *
 * const blobs = toBlobs({ data: '0x1234' })
 * const commitments = blobsToCommitments({ blobs, kzg })
 * const proofs = blobsToProofs({ blobs, commitments, kzg })
 * ```
 */
export function blobsToProofs(parameters) {
  const {
    kzg
  } = parameters;
  const to = parameters.to ?? (typeof parameters.blobs[0] === 'string' ? 'hex' : 'bytes');
  const blobs = typeof parameters.blobs[0] === 'string' ? parameters.blobs.map(x => hexToBytes(x)) : parameters.blobs;
  const commitments = typeof parameters.commitments[0] === 'string' ? parameters.commitments.map(x => hexToBytes(x)) : parameters.commitments;
  const proofs = [];
  for (let i = 0; i < blobs.length; i++) {
    const blob = blobs[i];
    const commitment = commitments[i];
    proofs.push(Uint8Array.from(kzg.computeBlobKzgProof(blob, commitment)));
  }
  return to === 'bytes' ? proofs : proofs.map(x => bytesToHex(x));
}
