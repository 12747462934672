import { hexToBytes } from '../encoding/toBytes.js';
import { bytesToHex } from '../encoding/toHex.js';
/**
 * Compute commitments from a list of blobs.
 *
 * @example
 * ```ts
 * import { blobsToCommitments, toBlobs } from 'viem'
 * import { kzg } from './kzg'
 *
 * const blobs = toBlobs({ data: '0x1234' })
 * const commitments = blobsToCommitments({ blobs, kzg })
 * ```
 */
export function blobsToCommitments(parameters) {
  const {
    kzg
  } = parameters;
  const to = parameters.to ?? (typeof parameters.blobs[0] === 'string' ? 'hex' : 'bytes');
  const blobs = typeof parameters.blobs[0] === 'string' ? parameters.blobs.map(x => hexToBytes(x)) : parameters.blobs;
  const commitments = [];
  for (const blob of blobs) commitments.push(Uint8Array.from(kzg.blobToKzgCommitment(blob)));
  return to === 'bytes' ? commitments : commitments.map(x => bytesToHex(x));
}
