import { BaseError } from '../../errors/base.js';
import { createCursor } from '../cursor.js';
import { hexToBytes } from './toBytes.js';
import { bytesToHex } from './toHex.js';
export function toRlp(bytes, to = 'hex') {
  const encodable = getEncodable(bytes);
  const cursor = createCursor(new Uint8Array(encodable.length));
  encodable.encode(cursor);
  if (to === 'hex') return bytesToHex(cursor.bytes);
  return cursor.bytes;
}
export function bytesToRlp(bytes, to = 'bytes') {
  return toRlp(bytes, to);
}
export function hexToRlp(hex, to = 'hex') {
  return toRlp(hex, to);
}
function getEncodable(bytes) {
  if (Array.isArray(bytes)) return getEncodableList(bytes.map(x => getEncodable(x)));
  return getEncodableBytes(bytes);
}
function getEncodableList(list) {
  const bodyLength = list.reduce((acc, x) => acc + x.length, 0);
  const sizeOfBodyLength = getSizeOfLength(bodyLength);
  const length = (() => {
    if (bodyLength <= 55) return 1 + bodyLength;
    return 1 + sizeOfBodyLength + bodyLength;
  })();
  return {
    length,
    encode(cursor) {
      if (bodyLength <= 55) {
        cursor.pushByte(0xc0 + bodyLength);
      } else {
        cursor.pushByte(0xc0 + 55 + sizeOfBodyLength);
        if (sizeOfBodyLength === 1) cursor.pushUint8(bodyLength);else if (sizeOfBodyLength === 2) cursor.pushUint16(bodyLength);else if (sizeOfBodyLength === 3) cursor.pushUint24(bodyLength);else cursor.pushUint32(bodyLength);
      }
      for (const {
        encode
      } of list) {
        encode(cursor);
      }
    }
  };
}
function getEncodableBytes(bytesOrHex) {
  const bytes = typeof bytesOrHex === 'string' ? hexToBytes(bytesOrHex) : bytesOrHex;
  const sizeOfBytesLength = getSizeOfLength(bytes.length);
  const length = (() => {
    if (bytes.length === 1 && bytes[0] < 0x80) return 1;
    if (bytes.length <= 55) return 1 + bytes.length;
    return 1 + sizeOfBytesLength + bytes.length;
  })();
  return {
    length,
    encode(cursor) {
      if (bytes.length === 1 && bytes[0] < 0x80) {
        cursor.pushBytes(bytes);
      } else if (bytes.length <= 55) {
        cursor.pushByte(0x80 + bytes.length);
        cursor.pushBytes(bytes);
      } else {
        cursor.pushByte(0x80 + 55 + sizeOfBytesLength);
        if (sizeOfBytesLength === 1) cursor.pushUint8(bytes.length);else if (sizeOfBytesLength === 2) cursor.pushUint16(bytes.length);else if (sizeOfBytesLength === 3) cursor.pushUint24(bytes.length);else cursor.pushUint32(bytes.length);
        cursor.pushBytes(bytes);
      }
    }
  };
}
function getSizeOfLength(length) {
  if (length < 2 ** 8) return 1;
  if (length < 2 ** 16) return 2;
  if (length < 2 ** 24) return 3;
  if (length < 2 ** 32) return 4;
  throw new BaseError('Length is too large.');
}
