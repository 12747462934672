import { AbiEventNotFoundError } from '../../errors/abi.js';
import { FilterTypeNotSupportedError } from '../../errors/log.js';
import { toBytes } from '../encoding/toBytes.js';
import { keccak256 } from '../hash/keccak256.js';
import { toEventSelector } from '../hash/toEventSelector.js';
import { encodeAbiParameters } from './encodeAbiParameters.js';
import { formatAbiItem } from './formatAbiItem.js';
import { getAbiItem } from './getAbiItem.js';
const docsPath = '/docs/contract/encodeEventTopics';
export function encodeEventTopics(parameters) {
  const {
    abi,
    eventName,
    args
  } = parameters;
  let abiItem = abi[0];
  if (eventName) {
    const item = getAbiItem({
      abi,
      name: eventName
    });
    if (!item) throw new AbiEventNotFoundError(eventName, {
      docsPath
    });
    abiItem = item;
  }
  if (abiItem.type !== 'event') throw new AbiEventNotFoundError(undefined, {
    docsPath
  });
  const definition = formatAbiItem(abiItem);
  const signature = toEventSelector(definition);
  let topics = [];
  if (args && 'inputs' in abiItem) {
    const indexedInputs = abiItem.inputs?.filter(param => 'indexed' in param && param.indexed);
    const args_ = Array.isArray(args) ? args : Object.values(args).length > 0 ? indexedInputs?.map(x => args[x.name]) ?? [] : [];
    if (args_.length > 0) {
      topics = indexedInputs?.map((param, i) => {
        if (Array.isArray(args_[i])) return args_[i].map((_, j) => encodeArg({
          param,
          value: args_[i][j]
        }));
        return args_[i] ? encodeArg({
          param,
          value: args_[i]
        }) : null;
      }) ?? [];
    }
  }
  return [signature, ...topics];
}
function encodeArg({
  param,
  value
}) {
  if (param.type === 'string' || param.type === 'bytes') return keccak256(toBytes(value));
  if (param.type === 'tuple' || param.type.match(/^(.*)\[(\d+)?\]$/)) throw new FilterTypeNotSupportedError(param.type);
  return encodeAbiParameters([param], [value]);
}
