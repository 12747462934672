import { bytesPerBlob, bytesPerFieldElement, fieldElementsPerBlob, maxBytesPerTransaction } from '../../constants/blob.js';
import { BlobSizeTooLargeError, EmptyBlobError } from '../../errors/blob.js';
import { createCursor } from '../cursor.js';
import { size } from '../data/size.js';
import { hexToBytes } from '../encoding/toBytes.js';
import { bytesToHex } from '../encoding/toHex.js';
/**
 * Transforms arbitrary data to blobs.
 *
 * @example
 * ```ts
 * import { toBlobs, stringToHex } from 'viem'
 *
 * const blobs = toBlobs({ data: stringToHex('hello world') })
 * ```
 */
export function toBlobs(parameters) {
  const to = parameters.to ?? (typeof parameters.data === 'string' ? 'hex' : 'bytes');
  const data = typeof parameters.data === 'string' ? hexToBytes(parameters.data) : parameters.data;
  const size_ = size(data);
  if (!size_) throw new EmptyBlobError();
  if (size_ > maxBytesPerTransaction) throw new BlobSizeTooLargeError({
    maxSize: maxBytesPerTransaction,
    size: size_
  });
  const blobs = [];
  let active = true;
  let position = 0;
  while (active) {
    const blob = createCursor(new Uint8Array(bytesPerBlob));
    let size = 0;
    while (size < fieldElementsPerBlob) {
      const bytes = data.slice(position, position + (bytesPerFieldElement - 1));
      // Push a zero byte so the field element doesn't overflow the BLS modulus.
      blob.pushByte(0x00);
      // Push the current segment of data bytes.
      blob.pushBytes(bytes);
      // If we detect that the current segment of data bytes is less than 31 bytes,
      // we can stop processing and push a terminator byte to indicate the end of the blob.
      if (bytes.length < 31) {
        blob.pushByte(0x80);
        active = false;
        break;
      }
      size++;
      position += 31;
    }
    blobs.push(blob);
  }
  return to === 'bytes' ? blobs.map(x => x.bytes) : blobs.map(x => bytesToHex(x.bytes));
}
