import { blobsToCommitments } from './blobsToCommitments.js';
import { blobsToProofs } from './blobsToProofs.js';
import { toBlobs } from './toBlobs.js';
/**
 * Transforms arbitrary data (or blobs, commitments, & proofs) into a sidecar array.
 *
 * @example
 * ```ts
 * import { toBlobSidecars, stringToHex } from 'viem'
 *
 * const sidecars = toBlobSidecars({ data: stringToHex('hello world') })
 * ```
 *
 * @example
 * ```ts
 * import {
 *   blobsToCommitments,
 *   toBlobs,
 *   blobsToProofs,
 *   toBlobSidecars,
 *   stringToHex
 * } from 'viem'
 *
 * const blobs = toBlobs({ data: stringToHex('hello world') })
 * const commitments = blobsToCommitments({ blobs, kzg })
 * const proofs = blobsToProofs({ blobs, commitments, kzg })
 *
 * const sidecars = toBlobSidecars({ blobs, commitments, proofs })
 * ```
 */
export function toBlobSidecars(parameters) {
  const {
    data,
    kzg,
    to
  } = parameters;
  const blobs = parameters.blobs ?? toBlobs({
    data: data,
    to
  });
  const commitments = parameters.commitments ?? blobsToCommitments({
    blobs,
    kzg: kzg,
    to
  });
  const proofs = parameters.proofs ?? blobsToProofs({
    blobs,
    commitments,
    kzg: kzg,
    to
  });
  const sidecars = [];
  for (let i = 0; i < blobs.length; i++) sidecars.push({
    blob: blobs[i],
    commitment: commitments[i],
    proof: proofs[i]
  });
  return sidecars;
}
